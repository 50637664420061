<template>
  <div>
    <v-card class="greeting-card">
      <v-row class="ma-0 pa-0">
        <v-card-text>
          <v-img
            :src="require('@/assets/images/logos/main-logo.png')"
            max-height="800"
            alt="logo"
            contain
            eager
            class="app-logo"
          ></v-img>
        </v-card-text>
      </v-row>
    </v-card>
    <v-dialog v-model="is_change_password" persistent max-width="50%">
      <ChangePassword></ChangePassword>
    </v-dialog>
    <v-dialog v-model="is_need_acknowledge_memo" persistent max-width="80%">
      <ListOfMemosNotYetAcknowledge
        :data_items="data_items_memo"
        v-on:data="list_of_tobe_akcnowledge_memo"
      ></ListOfMemosNotYetAcknowledge>
    </v-dialog>
  </div>
</template>

<script>
  import {mapActions, mapGetters} from "vuex";
  import ChangePassword from "@/views/dashboard/ChangePassword";
  import ListOfMemosNotYetAcknowledge from "@/views/dashboard/ListOfMemosNotYetAcknowledge";

  export default {
    computed: {
      ...mapGetters('authentication', ['position', 'need_change_password', 'employee_id']),
    },
    components: {
      ChangePassword,
      ListOfMemosNotYetAcknowledge,
    },
    data() {
      return {
        is_change_password: false,
        data_items_memo: [],
        is_need_acknowledge_memo: false,
      }
    },
    mounted() {
      this.is_change_password = this.need_change_password
      this.list_of_tobe_akcnowledge_memo()
      switch (this.$router.currentRoute.name) {
        case 'admin':
          this.view_as_change('ADMIN')
          break
        case 'employee':
          this.view_as_change('EMPLOYEE')
          break
          break
      }
    },
    methods: {
      ...mapActions('authentication', ['change_position']),
      ...mapActions('employee_memo_history', ['list_of_not_yet_acknowledge_memo']),
      ...mapActions('ggc_loans', ['generate_penalty_ggc_loan']),
      list_of_tobe_akcnowledge_memo() {
        this.list_of_not_yet_acknowledge_memo({
          employee_id: this.employee_id,
        })
          .then(response => {
            this.data_items_memo = response.data
            this.is_need_acknowledge_memo = this.data_items_memo.length > 0
          })
      },
      view_as_change(value) {
        this.change_position({
          position: value
        })
      }
    }
  }
</script>
